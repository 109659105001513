@import '../variables';
@import '../../../../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.ag-theme-custom {
  @include ag-theme-alpine(
    (
      foreground-color: $text-color,
      row-height: 35px,
      header-height: 35px,
      //grid-size: 3px,
      data-color: $text-color,
      font-family: (
        'Open Sans',
        sans-serif,
      ),
      font-size: 12px,
      border-color: $border-color-light,
      row-border-color: $border-color-light,
      cell-horizontal-padding: 8px,
      header-foreground-color: lighten($text-color, 5%),
      header-background-color: $panel-default-heading-bg,
      odd-row-background-color: #ffffff,
      widget-container-vertical-padding: 10px,
      widget-container-horizontal-padding: 10px,
      icon-font-family: 'FontAwesome5Free',
      icons-data: null,
      icon-size: 12px,
      icons-font-codes: (
        'aggregation': '\f247',
        'arrows': '\f0b2',
        'asc': '\f0d7',
        'cancel': '\f057',
        'chart': '\f080',
        'color-picker': '\f576',
        'columns': '\f0db',
        'contracted': '\f146',
        'copy': '\f0c5',
        'cross': '\f00d',
        'desc': '\f0d8',
        'expanded': '\f0fe',
        'eye-slash': '\f070',
        'eye': '\f06e',
        'filter': '\f0b0',
        'first': '\f100',
        'grip': '\f58e',
        'group': '\f5fd',
        'last': '\f101',
        'left': '\f060',
        'linked': '\f0c1',
        'loading': '\f110',
        'maximize': '\f2d0',
        'menu': '\f0c9',
        'minimize': '\f2d1',
        'next': '\f105',
        'none': '\f338',
        'not-allowed': '\f05e',
        'paste': '\f0ea',
        'pin': '\f276',
        'pivot': '\f074',
        'previous': '\f104',
        'right': '\f061',
        'save': '\f0c7',
        'small-down': '\f107',
        'small-left': '\f104',
        'small-right': '\f105',
        'small-up': '\f106',
        'tick': '\f00c',
        'tree-closed': '\f105',
        'tree-indeterminate': '\f068',
        'tree-open': '\f107',
        'unlinked': '\f127',
        'radio-button-off': '\f111',
        'radio-button-on': '\f192',
        'checkbox-off': '\f0c8',
        'checkbox-on': '\f14a',
      ),
    )
  );

  .ag-icon {
    font-weight: bold;
    min-width: 12px;
  }

  .ag-root-wrapper-body {
    .ag-checkbox-input-wrapper {
      background: none;
    }

    .ag-checkbox-input-wrapper::after {
      font-family: 'FontAwesome5FreeRegular';
      font-weight: bold;
      content: '\f0c8';
      color: $btn-info-border;
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
      font-family: 'FontAwesome5Free';
      content: '\f00c';
      color: $brand-primary;
    }

    .ag-checkbox-input-wrapper.ag-indeterminate::after {
      font-family: 'FontAwesome5Free';
      content: '\f068';
      color: gray;
    }
  }

  .ag-header {
    border-bottom-width: 3px;
  }

  .ag-cell.ag-cell-focus {
    border: 0;
  }

  .ag-filter-apply-panel {
    padding: 5px 10px;
  }

  .ag-standard-button {
    padding: 3px 6px;
  }

  .ag-picker-field-wrapper {
    border-radius: 0;
  }

  .ag-menu {
    background: $navbar-default-bg;
  }

  .ag-column-menu-visible .ag-header-cell-menu-button {
    opacity: 1;
  }
}

.panel > .ag-theme-custom,
.panel > .panel-body > .ag-theme-custom {
  .ag-root-wrapper {
    border: 0;
  }
}

.selectable-table {
  .ag-row {
    cursor: pointer;
  }
}
