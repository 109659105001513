@import '../../../assets/sass/variables';
@media (max-width: 991px) {
  .mobile-filter-menu-bar {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    .filter-menu-bar-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      i {
        margin-inline: 3px;
      }
    }
    .filter-menu-bar-btn {
      font-size: 12px;
      padding: 5px;
      color: $gray;
    }
  }
  .collapse-btn {
    p {
      margin: 0;
    }
    margin: 10px 0 10px 0;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .collapsed-hr {
    margin: 5px;
  }
  .collapse-button-content {
    padding-inline: 20px;
    max-height: 50vh;
    overflow-y: scroll;
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
  .modal-button-container {
    padding-bottom: 20px;
  }
  .collapse-button-content {
    .datetime-picker-dropdown {
      position: fixed !important;
      top: calc(50% - 275px / 2) !important;
      left: calc(50% - 260px / 2) !important;
    }
  }
}
