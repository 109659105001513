@import '../../assets/sass/variables';

.mobile-filter-menu-bar {
  display: none;
  border-bottom: 1px solid $border-color;
}
#desktop-filter-menu-bar {
  display: none;
}
.navbar-item-desc {
  font-size: 11px;
}
@media (min-width: 992px) {
  #responsive-filter-menu-bar {
    float: right;
    overflow: auto;
    width: 300px;
    top: 0;
    right: 0;
    left: auto;
    z-index: 2;
    background: #fff;
    border-left: 1px solid $border-color;
    position: static;
    height: 100%;
    &.view-sidebar,
    &.view-sidebar-sm,
    &.view-sidebar-right {
      float: right;
      overflow: auto;
      width: 300px;
      top: 0;
      right: 0;
      left: auto;
      z-index: 2;
      background: #fff;
      border-left: 1px solid $border-color;
      position: static;
      height: 100%;
    }
  }
}
@media (min-width: 992px) {
  #responsive-filter-menu-bar {
    .view-sidebar + div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 360px;
      overflow-y: scroll;
    }
    .view-sidebar-sm + div {
      left: 300px;
    }

    .view-sidebar.view-sidebar-right + div {
      left: 0;
      right: 360px;
    }

    .view-sidebar.view-sidebar-right.view-sidebar-sm + div {
      left: 0;
      right: 300px;
    }
  }
}

@media (max-width: 991px) {
  #responsive-filter-menu-bar {
    height: auto;
    &.view-sidebar,
    &.view-sidebar-sm,
    &.view-sidebar-right {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
.modal-header.filter-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    width: 95%;
    margin: 0;
  }
  .filter-modal-close-btn {
    width: 5%;
    color: #fff;
    font-weight: 500;
    font-size: 30px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }
}

.hide-desktop {
  z-index: inherit;
  height: 100%;
  animation: hide_desktop 0.5s 1 forwards;
}

.show-desktop {
  z-index: inherit;
  height: 0%;
  animation: 1s 1 forwards show_desktop;
}

@keyframes hide_desktop {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes show_desktop {
  0% {
    height: 0%;
  }
  50% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
