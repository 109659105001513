.toggle-switch {
  margin: 0;
  font-size: 1.2em;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  width: 1.6em;
  height: .6em;
  background: $gray-light;

  input[type='checkbox'] {
    display: none;
  }

  &:after {
    content: '';
    background: $gray-lightest;
    position: absolute;
    top: -.2em;
    //left: -2px;
    right: calc(100% - .9em);
    border-radius: 50%;
    border: 1px solid $gray-light;
    width: 1em;
    height: 1em;
    transition: right .2s linear;
  }

  &.active {
    &:after {
      left: auto;
      right: - 0.1em;
      background: $btn-info-bg;
      border-color: $btn-info-border;
    }
  }

  &.indeterminate {
    &:after {
      left: 50%;
      transform: translateX(-50%);
      background: $btn-warning-bg;
      border-color: $btn-warning-border;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: .8;
  }

  &.toggle-switch-lg {
    width: 2em;

    &:after {
      top: -.3em;
      width: 1.3em;
      height: 1.3em;
      right: calc(100% - 1.1em);
    }

    &.active {
      &:after {
        right: - 0.25em;
      }
    }
  }
}
