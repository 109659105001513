.home-main {
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  margin: 0 -3 * $spacing;
  padding: 0 5 * $spacing;
}

.home-shortcuts {
  margin: 0 -2.5px 0;
  padding-bottom: 20px;
  min-height: 500px;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .shortcut {
    width: 33.3%;
    font-size: 13px;
    float: left;
    color: #fff;
    text-align: center;
    text-decoration: none;
    padding: 2.5px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.double {
      width: 50%;
    }

    &.full {
      width: 100%;
    }

    .shortcut-content {
      background: $green-base;
      color: $gray-lightest;
      border-radius: 10px;
      box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
      height: 150px;

      &:hover {
        background: darken($green-base, 5%);
        color: #fff;

        .link-icon {
          color: #fff;
        }
      }

      &:active {
        transform: scale(0.95);
      }

      &.primary {
        background: $green-base;

        &:hover {
          background: darken($green-base, 5%);
          color: #fff;

          .link-icon {
            color: #fff;
          }
        }
      }

      .link-icon {
        display: block;
        font-size: 50px;
        line-height: 90px;
        color: $gray-lightest;

        i {
          vertical-align: sub;
        }

        img {
          height: 90px;
          vertical-align: top;
        }
      }

      .link-text-wrapper {
        height: 36px;
        margin: 0;
        font-size: 13px;
        position: relative;

        .link-text {
          word-wrap: break-word;
          position: absolute;
          padding: 0 5px;
          width: 100%;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .important {
    .shortcut {
      background-color: #40639b;
      margin: 0;
      position: absolute;

      &:hover {
        background-color: lighten(#40639b, 5%);
      }
    }

    .important_description {
      padding-left: 150px;

      > * {
        height: 150px;
      }
    }
  }
}

.home-plugins {
  text-align: center;

  .x-plugin-icon {
    margin-right: 5px;
    width: 110px;
  }
}

@media (min-width: 1600px) {
  .home-shortcuts {
    .shortcut {
      width: 25%;
    }
  }
}

@media (max-width: $screen-md-max) {
  .home-shortcuts {
    text-align: center;

    .shortcut {
      width: 33.3333%;
    }

    .important {
      .shortcut {
        width: 150px;
        left: 0;
      }
    }
  }
}

@media (max-width: $screen-ms-max) {
  .home-shortcuts {
    min-height: 0;
    text-align: center;

    .shortcut {
      width: 50%;
    }

    .important {
      .shortcut {
        width: 150px;
        left: 0;
      }
    }
  }
}

@media (max-width: $screen-ms-min) {
  .home-shortcuts {
    min-height: 0;

    .shortcut {
      width: 100%;
    }

    .important {
      .shortcut {
        position: static;
        display: block;
        width: 100%;
      }

      .important_description {
        padding: 0;

        > * {
          height: auto;
        }
      }
    }
  }
}
