$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-base: $font-family-sans-serif;

$gray-base: #222;
$gray-darker: #425c65; // #222
$gray-dark: #5b7179; // #333
$gray: lighten($gray-dark, 10%); // #555
$gray-light: #b8bfd9; // #777
$gray-lighter: #e3ebee; // #eee
$gray-lightest: #f1f5f8;

$green-base: #5e6eb4;

$brand-primary: #2c303b; // #337ab7
$brand-primary-dark: darken($brand-primary, 10%);
$brand-success: #00a86b;
$brand-info: #57889c;
$brand-warning: #f0ad4e;
$brand-danger: #f16b46;
$brand-secondary: #2172c3;

$state-info-text: #1a5e7f;
$state-info-bg: #aed3e5;
$input-color: $gray;

$label-default-bg: darken($gray-light, 5%);
$label-success-bg: $brand-success;
$label-warning-bg: $brand-warning;

$border-color: darken($gray-lighter, 3%);
$border-color-light: $gray-lighter;
$border-dark: #becdd9;

$body-bg: #f5f5fb;
$text-color: $gray-dark;
$text-black: $text-color;
$text-muted: lighten($text-color, 20%);
$text-selection-bg: $gray-light;
$headings-small-color: $gray-light;
$link-color: $brand-primary;
$close-color: #000;

// Button
$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;

$btn-success-bg: $brand-success;
$btn-warning-bg: $brand-warning;

// Navbar
$navbar-height: 40px;
$navbar-inverse-color: #838b94;
$navbar-inverse-bg: #2e363f;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-active-bg: lighten($navbar-inverse-bg, 5%);
$navbar-inverse-toggle-hover-bg: $navbar-inverse-link-active-bg;
$navbar-inverse-toggle-border-color: #555;
$navbar-inverse-link-hover-color: lighten($navbar-inverse-color, 20%);

$navbar-default-bg: $body-bg;
$navbar-default-border: $border-color;
$navbar-default-toggle-hover-bg: lighten($navbar-inverse-bg, 20%);
$navbar-default-toggle-border-color: lighten($navbar-inverse-bg, 20%);
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-brand-color: #fff;

$grid-gutter-width: 10px;
$container-desktop: (840px + $grid-gutter-width);
$container-large-desktop: (990px + $grid-gutter-width);

//Panel
$panel-heading-padding: 0 10px;
$panel-default-heading-bg: $gray-lightest;
$panel-footer-bg: $gray-lightest;
$panel-bg: #fff;
$panel-default-border: $border-color;
$panel-inner-border: $border-color;

// Well
$well-padding: 15px;
$well-border: $panel-default-border;
$well-bg: #f5f5f5;

// List Group
$list-group-bg: $panel-bg;
$list-group-link-color: $text-color;
$list-group-bg: $panel-bg;
$list-group-border: $border-color;

// Modal
$modal-content-border-color: transparent;
$modal-header-border-color: $border-color;
$modal-header-border-color: $border-color;

// Input
$input-border: $border-color;
$input-border-focus: darken($border-color, 20%);

// breadcrumb
$breadcrumb-bg: #fff;
$breadcrumb-active-color: $gray;

//== Tabs
$nav-tabs-border-color: $border-color;
$nav-tabs-link-hover-border-color: $gray-lighter;

$nav-tabs-active-link-hover-bg: #fff;
$nav-tabs-active-link-hover-color: $gray-dark;
$nav-tabs-active-link-hover-border-color: $border-color;
$nav-tabs-justified-link-border-color: $border-color;
$nav-tabs-justified-active-link-border-color: $gray-lightest;

// Dropdown
$dropdown-bg: #fff;
$dropdown-divider-bg: darken($border-color, 3%);
$dropdown-link-hover-bg: lighten($panel-bg, 3%);
$dropdown-link-active-color: $text-color;
$dropdown-link-active-bg: $body-bg;

// Thumbnail
$thumbnail-border: $border-color;

// Table
$table-bg-accent: $gray-lighter;
$table-bg-hover: $gray-lighter;
$table-border-color: $border-color;
$table-odd-bg: $gray-lightest;

$table-filter-color: $gray;
$table-radio-bg: #fff;
$table-radio-border: darken($border-color, 10%);
$table-radio-active-border: $brand-primary, 10%;

// Popover
$popover-bg: $panel-bg;

// Pagination
$pagination-bg: #fff;
$pagination-border: $border-color;
$pagination-hover-border: $border-color;
$pagination-disabled-bg: #fff;
$pagination-disabled-color: $gray-light;
$pagination-disabled-border: $border-color;

$legend-border-color: darken($border-color, 3%);

$pre-bg: darken($panel-bg, 2%);

$hr-border: $border-color;

$screen-lg-min: 1300px;
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

$input-bg: lighten($body-bg, 5%);
$input-border: $border-color;

// Mid-Small breakpoint
$screen-ms: 480px !default;
$screen-ms-min: $screen-ms;
$screen-ms-max: ($screen-sm-min - 1);

// Redefined Extra Small max value
$screen-xs-max-new: ($screen-ms-min - 1);

$spacing: floor(($grid-gutter-width / 2));
